import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Floating } from "./Floating";
import { calculateOrderAmount, clean, Constants, generateCopy, setInLocalStorage, useToken } from "./Hooks";
import { Modal } from "./Modal";
import { Product } from "./Product";
import { TopBar } from "./TopBar";

export function Catalog(){

    const params = useParams();
    const token = useToken();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [copyProducts, setCopyProducts] = useState([]);
    const [modalDeleteOrder, setModalDeleteOrder] = useState(false);
    const [timestamp, setTimestamp] = useState(new Date().getTime());

    const isInCategory = (product, idCategory) => {
        const index = ( product.products_categories_hierarchy ?? []).findIndex(i => i.category && i.category.id.toString() == idCategory);
        return index > -1;
    };

    useEffect(() => {

        if(!token || !Constants.currentTable){
            navigate("/");
            return;
        }

        if(localStorage.getItem('products')){
            const prds = JSON.parse(localStorage.getItem('products'));
            const _prds = [];
            prds.forEach(p => {
                if(isInCategory(p, params.id)){
                    _prds.push(p);
                }
            });
            setProducts(_prds);
            setCopyProducts( generateCopy(_prds) );
        }

    }, []);

    return (
        <div className="catalog">
            <TopBar displayArrowBack></TopBar>

            <Modal display={modalDeleteOrder} message={"Attenzione"} close={() => setModalDeleteOrder(false)}>
                <p>Sei sicuro di voler cancellare l'ordine</p>
                <div style={{display: "flex", justifyContent: "end", gap: "10px"}}>
                    <button className="btn btn-danger" onClick={() => setModalDeleteOrder(false)}>ANNULLA</button>
                    <button className="btn btn-primary" onClick={() => {
                        clean();
                        navigate("/tables");
                    }}>CONFERMA</button>
                </div>
            </Modal>
            
            <Floating style={{right: "150px", display: Constants.currentTable &&  Constants.currentTable.isOpened ? "" : "none" }} click={() => {
                navigate(`/sent/${Constants.currentTable.id.toString()}`);
            }}>
                <i class="fas fa-archive"></i>
            </Floating>

            <Floating click={() => {
                if(Constants.OpenedParkedOrders){
                    Constants.OpenedParkedOrders = false;
                    Constants.Cart = [];
                    Constants.Exit = -1;
                    Constants.currentTable = null;
                    localStorage.removeItem('cart');
                    navigate('/');
                    return;
                }
                Constants.Exit = -1;
                navigate(`/cart/1`);
            }}>
                <div>
                    <i class="fas fa-paper-plane"></i>
                </div>
            </Floating>

            <Floating style={{right: "80px"}} click={() => {
                navigate(`/parked_orders/${Constants.currentTable.id.toString()}`);
            }}>
                <i class="fas fa-list-ol"></i>
            </Floating>

            <Floating style={{left: "10px", backgroundColor: "rgb(255, 59, 59)"}} click={() => {
                if(Constants.Cart.length > 0)
                    setModalDeleteOrder(true);
            }}>
                <i class="fas fa-trash-alt"></i>
            </Floating>
            
            <div className="catalog-page" style={{display: "flex", flexDirection: "column", gap: "15px", padding: "15px"}}>
                {
                    Constants.currentTable ? 
                        <div>
                            <div style={{color: "var(--text)", fontWeight: "600"}}>Tavolo utilizzato: {Constants.currentTable.description}</div>
                        </div>
                    :
                        <></>
                }
                <input type={"text"} className="form-control" placeholder="Ricerca prodotto.." onChange={(e) => {
                    
                    if(!e.target.value){
                        setProducts(copyProducts);
                        return;
                    }

                    const _products = copyProducts.filter(p => p.description.trim().toLowerCase().includes(e.target.value.toLowerCase().trim()));
                    setProducts(_products);

                }}/>
                <div style={{display: "flex",  overflowX: "auto", gap: "10px"}}>
                    {
                        !Constants.OpenedParkedOrders ? 
                            <button className="btn btn-danger" onClick={() => {
                                Constants.Exit = -1;
                                setTimestamp(new Date().getTime());                        
                            }}><i class="fas fa-times"></i></button>                    
                        :
                            <div style={{color: "var(--text)", fontWeight: "600"}} onClick={() => {
                                navigate('/parked_orders/' + Constants.currentTable.id.toString());
                            }}>
                                Stai lavorando con l'uscita {Constants.Exit}
                            </div>
                    }
                    {
                        !Constants.OpenedParkedOrders ? 
                            [2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => 
                                <button className={`btn btn-${item == Constants.Exit ? "success" : "primary"}`} style={{height: "60px"}} key={`exit_btn_${index}`} onClick={() => {
                                    if(Constants.Exit == item){
                                        Constants.Exit = -1;
                                    }else{
                                        Constants.Exit = item;
                                    }
                                    setTimestamp(new Date().getTime());
                                }}>{item}° USCITA</button>
                            )
                        :
                            <></>
                    }
                </div>
                {
                    products && products.length > 0 ? 
                        products.map((product, index) => <Product product={product} key={product.id.toString()} toCatalog={() => {
                            setTimestamp(new Date().getTime());
                        }}/>)
                    :   
                        <></>
                }
                <div style={{height: "100px"}}/>
            </div>
        </div>
    );
}
