import { useNavigate } from "react-router-dom";
import { Constants, useEndpoint, useVersion } from "./Hooks";
import { useState } from "react";

export function TopBar({displayArrowBack}){
    const [modal, setModal] = useState(false)
    const endpoint  = useEndpoint();
    const navigate  = useNavigate();


    return (
        <div className="top-bar">
            <div style={{ display: modal ? 'flex' :'none', justifyContent:'center', alignItems: 'center'   ,backgroundColor:'rgba(0,0,0,0.5)', width:'100dvw', height:'100dvh', position:'absolute', zIndex:'10'}}>
                <div style={{ width:'70%', height:'20%', backgroundColor:'white', borderRadius:'10px', position:'relative'}}>
                    <div style={{ position:'absolute', top:'10%', width:'100%', textAlign:'center'}}>Se esci il carrello verrà svuotato</div>
                    <div className="button" style={{ width:'100%', display:'flex', justifyContent:'space-around', position:'absolute', bottom:'10px'}}>
                       <div className="category-button" onClick={() => {
                        if( window.location.href.includes( 'categories/parent' ) ){
                            localStorage.setItem('cart', '[]')
                        } 
                        
                        navigate(-1)}}>Esci</div>
                        <div className="category-button" onClick={ ()=> setModal( false )}>Annulla</div>
                    </div>
                </div>
            </div>
            {
                displayArrowBack ? 
                    <div className="arrow-back" onClick={async () => {
                        if( window.location.href.includes( 'categories/parent' ) ){
                            setModal( true ) 
                            return
                        } 
                        navigate(-1);
                        
                        }}>
                        <i class="fas fa-arrow-left"></i>
                    </div>
                :
                    <></>
            }
            <img src={`${endpoint}/${Constants.Image}`}/>
        </div>
    );
}