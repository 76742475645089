import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Floating } from "./Floating";
import { calculateSentOrderAmount, setDate, Constants, getVariantsInCart, sendOrder, setInLocalStorage, syncCategories, useEndpoint, useToken } from "./Hooks";
import { Loader } from "./Loader";
import { TopBar } from "./TopBar";

export function Sent(){

    const [enterprise, setEnterprise] = useState(null);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [exit, setExit] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const token = useToken();

    const date = new Date()
    const hour = date.getHours();
    const minutes = date.getMinutes();

    useEffect( () => {
       
        if(!token){
            navigate("/");
            return;
        }

        const _enterprise = JSON.parse(localStorage.getItem('enterprise'));
        setEnterprise(_enterprise);

        if(params && params.exit){
            setExit(params.exit == "1" ? -1 : parseInt(params.exit));
        }

    }, []);

    return (
        <div className="cart">

            <TopBar displayArrowBack></TopBar>
            {
                displayLoader ? 
                    <Loader/>
                :
                    <></>
            }
            <div className="cart-page">
                <div className="receipt">
                    {
                        exit && Constants.currentSentOrder && Constants.currentSentOrder.length > 0 ?
                            <>
                                <div style={{textAlign: "center", width: "100%"}}>
                                    {
                                        enterprise && enterprise.business_name ? 
                                            <>
                                                <div style={{fontWeight: "bold"}}>
                                                    <div>{enterprise.business_name}</div>
                                                    <div>DOCUMENTO GESTIONALE</div>
                                                    <div>--- TAVOLO {Constants.currentTable.description} ---</div>
                                                </div>
                                                <div style={{width: "100%", textAlign: "center", whiteSpace: "nowrap", overflow: "hidden"}}>--------------------------------</div>
                                            </>
                                        :
                                            <></>
                                    }
                                </div>
                                <div className="header">
                                    <div style={{textAlign: "left"}}>DESCRIZIONE</div>
                                    <div style={{textAlign: "right"}}>PREZZO</div>
                                </div>
                            </>
                        :
                            <>
                                <div style={{fontWeight: "bold", textAlign: "center"}}>
                                    <div>NESSUN PRODOTTO INVIATO</div>
                                </div>
                            </>
                    }
                    <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                        {
                            exit && Constants.currentSentOrder && Constants.currentSentOrder.length > 0 ?
                                Constants.currentSentOrder.map( p => 
                                    <>
                                        <div className="body">
                                            <div>
                                                {
                                                  p.description
                                                }
                                            </div>
                                            <div>
                                                {
                                                  p.quantity.toFixed(2) + "x " + p.price.toFixed(2) + "€"
                                                }
                                            </div>
                                        </div>
                                         { 
                                            p.uuid_waiter.length > 0 & Array.isArray(p.uuid_waiter) ?
                                                <>
                                                    <div style={{fontSize: "12px", marginLeft: "10px", fontStyle: "italic"}}>
                                                        +&nbsp;
                                                        {
                                                            
                                                            p.uuid_waiter.map((variant, index) => 
                                                                <span>{`${variant.description} (+${variant.price} €) `}</span>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            :
                                                <></>
                                        } 
                                    </>
                                )
                            :
                                <></>
                        }
                    </div>
                    {
                        Constants.currentSentOrder && Constants.currentSentOrder.length > 0 ? 
                            <>
                                <div style={{width: "100%", textAlign: "center", whiteSpace: "nowrap", overflow: "hidden"}}>--------------------------------</div>
                                <div className="footer">
                                    <div>TOTALE</div>
                                    <div>{calculateSentOrderAmount().toFixed(2)} €</div>
                                </div>
                            </>
                        :
                            <></>
                    }
                </div>
            </div>
        </div>
    );
}