import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Tables } from './Tables';
import { Catalog } from './Catalog';
import { Variants } from './Variants';
import { CartObject } from './Cart';
import { ParkedOrders } from './ParkedOrders';
import { Constants, setNginxLocation } from './Hooks';
import { Categories } from './Categories';
import { Sent } from './Sent';

const root = ReactDOM.createRoot(document.getElementById('root'));
if(window.location.pathname !== "/"){
  window.location.href = "/";
}

if(window.location.hostname == "waiter.cassawow.it"){
  document.documentElement.style.setProperty('--primary', '#3a5d47');
  document.documentElement.style.setProperty('--primary-light', '#008833a1');
  document.documentElement.style.setProperty('--secondary', '#524f6b');
  Constants.Image = "files/static/media/easyappear_dylog_logo.png";
}

root.render(
  <BrowserRouter basename={window.location.pathname}>
    <Routes>
      <Route path='/' element={<App/>}></Route>
      <Route path='/tables' element={<Tables/>}></Route>
      <Route path='/catalogues/:id' element={<Catalog/>}></Route>
      <Route path='/parked_orders/:id' element={<ParkedOrders/>}></Route>
      <Route path='/variants/:uuid/:id_product' element={<Variants/>}></Route>
      <Route path='/categories/:id' element={<Categories/>}></Route>
      <Route path='/cart/:exit' element={<CartObject/>}></Route>
      <Route path='/sent/:exit' element={<Sent/>}></Route>
    </Routes>
  </BrowserRouter>
);

