import { useEffect, useState } from "react";
import { Constants, setInLocalStorage } from "./Hooks";
import { Modal } from "./Modal";

export function VariantModif({line, close, openList}){

    const [displayModal, setDisplayModal] = useState(false);
    const [newPrice, setNewPrice] = useState(0.00);
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const [variantUuid, setVariantUuid] = useState(null);

    return (
        <div style={{display: "flex", flexDirection :"column", gap: "10px"}}>
            <Modal message={"Modifica prezzo"} display={displayModal} close={() => setDisplayModal(false)}>
                <input className="form-control" placeholder="Nuovo prezzo" value={newPrice} onChange={(e) => {
                    setNewPrice(e.target.value);
                }}></input>
                <div style={{display: "flex", justifyContent: "end", gap: "10px", marginTop: "15px", borderTop: "1px solid rgba(0, 0, 0, 0.233)", paddingTop: "10px"}}>
                    <button className="btn btn-danger" onClick={() => setDisplayModal(false)}>CHIUDI</button>
                    <button className="btn btn-primary" onClick={() => {

                        const price =  parseFloat ( (newPrice ?? "0.00").toString().replace(",", ".") );
                        if(price && !isNaN(price)){
                            const index = Constants.Cart.findIndex(i => i.uuid == line.uuid);
                            if(index > -1){
                                const _index = Constants.Cart[index].variants.findIndex(v => v.uuid == variantUuid);
                                if(_index > -1){
                                    Constants.Cart[index].price -= Constants.Cart[index].variants[_index].price;
                                    Constants.Cart[index].variants[_index].price = price;
                                    Constants.Cart[index].price += price;
                                    setInLocalStorage();
                                }
                            }
                        }
                        setDisplayModal(false);

                    }}>AGGIORNA</button>
                </div>
            </Modal>
            {
                line && line.variants && line.variants.length > 0 ? 
                    line.variants.map((variant, index) => 
                        <div style={{display: "flex", justifyContent: "space-between", borderBottom: "1px solid rgba(0, 0, 0, 0.233)", paddingBottom: "5px"}} key={"variant_" + variant.uuid}>
                            <div>{variant.description}</div>
                            <div style={{display: "flex", gap: "10px"}}>
                                {/*<button className="btn btn-success" onClick={() => {
                                    setVariantUuid(variant.uuid);
                                    setNewPrice(0.00);
                                    setDisplayModal(true);
                                }}><i class="fas fa-pencil-alt"></i></button>*/}
                                <button className="btn btn-danger" onClick={() => {
                                    
                                    const price = line.variants[index].price;
                                    line.variants.splice(index, 1);
                                    setTimestamp(new Date().getTime());

                                    const _index = Constants.Cart.findIndex(c => c.uuid == line.uuid);
                                    Constants.Cart[_index].price -= price;

                                    setInLocalStorage();

                                    if(line.variants.length == 0){
                                        close();
                                    }

                                }}><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                    )
                :
                    <></>
            }
            <div style={{display: "flex", justifyContent: "end", gap: "10px"}}>
                <button className="btn btn-danger" onClick={() => close()}>CHIUDI</button>
                <button className="btn btn-primary" onClick={() => openList()}>APRI LISTA</button>
            </div>
        </div>
    );
}