import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Constants } from "./Hooks";
import { TopBar } from "./TopBar";

export function ParkedOrders(){

    const [commands, setCommands] = useState([]);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        const idTable = params.id;
        if(idTable){
            const parkedOrders = localStorage.getItem('parked_orders');
            if(parkedOrders){
                const parked_ordersJson = JSON.parse(parkedOrders);
                const index = parked_ordersJson.findIndex(i => i.table.toString() == idTable);
                if(index > -1){
                    
                    const exits = [];
                    (parked_ordersJson[index].products ?? []).forEach(product => {
                        const exit = product.exit;
                        const index = exits.findIndex(e => e.exit == exit);
                        if(index == -1){
                            exits.push({exit: exit, products: [product]});
                        }else{
                            exits[index].products.push(product);
                        }
                    });

                    setCommands(exits);
                }
            }
        }

    }, []);

    return (
        <div className="parked_orders">
            <TopBar displayArrowBack></TopBar>
            <div className="parked_orders_page">
                {
                    commands && commands.length > 0 ?
                        commands.sort((a, b) => parseInt(a.exit) - parseInt(b.exit)).map((item, index) =>
                            <div className="parked-orders-line" onClick={() => {

                                // Mandami nel carrello, con l'uscita di riferimento
                                const products = commands[index].products;
                                Constants.Cart = [...products];
                                Constants.Exit = item.exit;
                                Constants.OpenedParkedOrders = true;
                                navigate("/categories/parent");

                            }} key={`command_exit_${index}`}>
                                {item.exit}° USCITA --&gt;
                            </div>
                        )
                    :
                        <></>
                }
            </div>
        </div>
    );
}