import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Constants, NginxLocation, setNginxLocation, useEndpoint, useToken, useVersion } from "./Hooks";
import { Loader } from "./Loader";

function App() {

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();
  const token = useToken();
  const endpoint = useEndpoint();
  const version = useVersion();
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if(token){
      if(NginxLocation){
        localStorage.setItem('pointed_url', NginxLocation);
        window.location.href = "/";
        return;
      }
      const pointed_url = localStorage.getItem('pointed_url');
      if(pointed_url){
        setTimeout(() => {
          navigate(pointed_url);
        });
        localStorage.removeItem('pointed_url');
      }else{
        navigate("/tables");
      }
    }

    const email_ = localStorage.getItem('email');
    const password_ = localStorage.getItem('password');

    if(email_){
      setEmail(email_);
    }
    if(password_){
      setPassword(password_);
    }

  }, []);

  return (
    <div className="login-container">
      <div className='login-page'>
        <div>
          {
            // CASSAWOW => files/static/media/easyappear_dylog_logo.png
            // MALLBOX  => files/static/media/logo-white-mallbox-extended.png
          }
          <img src={`${endpoint}/${Constants.Image}`}/>
          <input type={"text"} className="form-control" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input type={"password"} className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
          <button className='btn btn-primary' onClick={ async () => {

            if(!email || !password) return;

            setLoading(true);

            const response = await fetch(
              `${endpoint}/api/mb/v1/jwt/bearer_auth`,
              {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  email: email,
                  password: password
                })
              }
            );

            setLoading(false);

            if(response && response.status == 201){
              const json = await response.json();
              if(json.Result == "OK"){
                const token = json.Json.token;
                localStorage.setItem('enterprise', JSON.stringify(json.Json.enterprise));
                localStorage.setItem('token', token);
                localStorage.setItem('email', email);
                localStorage.setItem('password', password);
                window.location.reload();
              }
            }

          }}>Login</button>
          <div style={{color: "white"}}>WebApp Cameriere vr. {version}</div>
          {
            loading ? 
              <Loader/>
            :
              <></>
          }
        </div>
      </div>
    </div>
  );
}

export default App;
