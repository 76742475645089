import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Constants, coperti, doEllips, generateCopy, generatePrice, getProductsInCart, getVariantsInCart, manageQuantity, setInLocalStorage, updateParkedOrder, useCart, useEndpoint, useToken } from "./Hooks";
import { Modal } from "./Modal";
import { VariantModif } from "./VariantModif";

export function Product({product, toCatalog}){

    const endpoint                          = useEndpoint();
    const [price, setPrice]                 = useState(0.00);
    const [lines, setLines]                 = useState([]);
    const [displayModal, setDisplayModal]   = useState(false);
    const [variants, setVariants]           = useState([]);
    const [openedLine, setOpenedLine]       = useState(null);
    const [init, setInit]                   = useState(false);
    const [set, setState]                   = useState();
    const navigate                          = useNavigate();
    const token                             = useToken();
    const [render, setRender]               = useState(false);
    var note                                = null;
    const input_note                        = useRef();

    const _setLines = (product) => {

        try{
            const productsInCart = getProductsInCart(product.id);
            if(productsInCart && productsInCart.length > 0){
                setLines(productsInCart);
                return;
            }
        }catch(_){}

        setLines([]);
    };

    const stopSell = async (id_product, status) => {
        fetch(
            `${endpoint}/api/mb/v1/product/stop_sell?status=${status}&id_product=${id_product}`,
            {
                method: "GET",
                headers: {
                    'jwt': token
                }
            }
        );
    };

    useEffect(() => {

        const _price = generatePrice(product.vat ? product.vat.value : 0.00, product.prices && product.prices.length > 0 ? product.prices[0].taxable : 0.00);
        setPrice(_price);

        _setLines(product);
        setInit(true);

    }, []);

    useEffect(() => {
        if(init){
            if(!displayModal){
                toCatalog();
            }
        }
    }, [displayModal]);

    return (
        <>
            <div className="product-card">
                <Modal message={"Varianti"} display={displayModal} close={() => setDisplayModal(false)}>
                    <VariantModif line={openedLine} close={() => setDisplayModal(false)} openList={() => {
                        setDisplayModal(false);
                        navigate(`/variants/${openedLine.uuid}/${openedLine.id}`);
                    }}/> 
                </Modal>
                <div style={{width: "80px"}}>
                    {
                        product.image && product.image.startsWith('https://backend.easyappear') ? 
                            <img src={product.image} style={{height: "100%", width: "100%", objectFit: "cover", borderRadius: "1000px"}}/>     
                        :
                            <img src={product.image ? `${endpoint}${product.image}` : `${endpoint}/files/static/media/placeholder.png`} style={{height: "100%", width: "100%", objectFit: "cover", borderRadius: "1000px"}}/> 
                    }
                </div>
                <div style={{width: "100%", textAlign: "center", lineHeight: "40px"}}>
                    <div className="product-description">{product.description}</div>
                    {
                        product.stop_sell == 1 ? 
                            <span style={{color: "red", fontWeight: "bold", fontSize: "15px"}} onClick={() => {
                                stopSell(product.id, 0);
                                product.stop_sell = 0;
                                setRender(!render);
                            }}>TERMINATO</span>
                        :
                            <span style={{color: "green", fontWeight: "bold", fontSize: "15px"}} onClick={() => {
                                stopSell(product.id, 1);
                                product.stop_sell = 1;
                                setRender(!render);
                                
                            }}>DISPONIBILE</span>
                    }
                </div>
                <div style={{width: "70px", justifyContent: "center", fontSize: "40px", textAlign: "right", lineHeight: "40px", display: product.stop_sell == 0 ? "block" : "none"}} onClick={() => {
                    
                    const obj = {
                        uuid: uuidv4(),
                        id: product.id,
                        code: product.code,
                        description: product.description,
                        price: price,
                        quantity: 1,
                        exit: Constants.Exit,
                        variants: []
                    };

                    Constants.Cart.push(obj);
                    setInLocalStorage();

                    if(toCatalog){
                        toCatalog();
                    }
                    _setLines(product);
                    updateParkedOrder();

                }}>
                    <div>+</div>
                </div>
            </div>
            {
                lines && lines.length > 0 ? 
                    lines.map((line, index_) => {
                        const variantDescription = getVariantsInCart(line.uuid);
                        return (
                            <div style={{paddingLeft: "15px", paddingRight: "15px"}} key={`line_product_${line.uuid}_${index_.toString()}`}>
                                <div className="line-product-parent">
                                    <div style={{width: "100%", textAlign: "center"}}>
                                        <small style={{fontStyle: "italic"}}>{line.exit == -1 ? "1" : (line.exit).toString()}° USCITA</small>
                                    </div>
                                    <div key={line.uuid} className="line-product">
                                        <div onClick={() => manageQuantity(line.uuid, "-", () => { toCatalog(); _setLines(product); updateParkedOrder(); })}>-</div>
                                        <div>{line.quantity}</div>
                                        <div onClick={() => manageQuantity(line.uuid, "+", () => { toCatalog(); _setLines(product); updateParkedOrder(); })}>+</div>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <input type={"text"} ref={input_note} className="form-control" style={{width: '100%'}} id="input_variant" placeholder="Aggiungi una nota" defaultValue={line.note ? line.note : ''} onChange={(e) => {
                                            if(e.target.value == ' '){
                                                e.target.value = '';
                                                return;
                                            }
                                            if( e.target.value.length == 100 ){
                                                e.target.value = e.target.value.slice(0, -1)
                                                return;
                                            }
                                            if( e.target.value.length > 100 ){
                                                e.target.value = '';
                                                return;
                                            }
                                            note = e.target.value ;
                                        }}/>
                                        <div style={{backgroundColor: 'white', padding: '5px', margin: '5px', fontSize: '0px', fontWeight: 'bold', color: 'orange' }} onClick={ () => {
                                            
                                            if(note != null && note != ''){
                                                line.variants.push({
                                                    uuid: uuidv4(),
                                                    price: 0,     
                                                    id: -1,    
                                                    description: note
                                                });
                                                note = "";
                                                input_note.current.value = '';
                                                setState(new Date());
                                            }

                                        }}>
                                            <img style={{width: '25px' , objectFit: 'cover',}} src='https://img.icons8.com/?size=512&id=12581&format=png'></img>
                                        </div>
                                    </div>
                                    <div className="line-product-variant" onClick={() => {
                                        if(line.variants && line.variants.length > 0){
                                            setOpenedLine(line);
                                            setDisplayModal(true);
                                        }else{
                                            navigate(`/variants/${line.uuid}/${line.id}`);
                                        }
                                    }}>
                                        
                                        {
                                            
                                            !variantDescription ?
                                                <span>VARIANTI</span>
                                            :
                                                <>
                                                    <span>{ variantDescription.description }</span>
                                                </>
                                                
                                        }
                                        &nbsp;&nbsp;<i class="fas fa-pencil-alt"></i>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                :
                    <></>
            }
        </>
    );
}