import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { calculateOrderAmount, Constants, generateCopy, setInLocalStorage, useToken } from "./Hooks";
import { Modal } from "./Modal";
import { TopBar } from "./TopBar";
import { v4 as uuidv4 } from 'uuid';
import { Floating } from "./Floating";

export function Variants(){

    const token = useToken();
    const [variants, setVariants] = useState([]);
    const [variantsCopy, setVariantsCopy] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const params = useParams();
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    // Variabili modal varianti
    const [overridePrice, setOverridePrice] = useState(0.00);
    const [variantId, setVariantId] = useState(null);
    const [variantDesc, setVariantDesc] = useState(null);

    var note = null;

    useEffect(() => {

        if(!token){
            navigate("/");
            return;
        }

        const _products = JSON.parse(localStorage.getItem('products'));
        setProducts(_products);

        // Ottieni le varianti del prodotto..
        const index = _products.findIndex(p => p.id.toString() == params.id_product.toString());
        if(index > -1){
            const vars = generateCopy( _products[index].variants );
            const _vars = [];
            (vars ?? []).forEach(v => {
                v.variant.price = v.override_price;
                _vars.push(v.variant);
            });
            setVariants(_vars);
            setVariantsCopy(generateCopy(_vars));
        }


    }, []);

    useEffect(() => {
        if(!displayModal){
            setOverridePrice(0.00);
            setVariantDesc(null);
            setVariantId(null);
        }
    }, [displayModal]);


    return (
        <div className="variants-container">
            <TopBar displayArrowBack></TopBar>
            <Modal message={"Variante"} display={displayModal} close={() => setDisplayModal(false)}>
                <label>Aggiungi variante</label>
                <input type={"number"} className="form-control" placeholder="Costo variante" value={overridePrice.toString()} style={{display: "none"}} onChange={(e) => {
                    setOverridePrice(e.target.value);
                }}></input>
                <div className="footer-confirm">
                    <button className="btn btn-danger" onClick={() => setDisplayModal(false)}>ANNULLA</button>
                    <button className="btn btn-primary" onClick={() => {

                        let price = 0.00;
                        try{
                            price = parseFloat( (overridePrice ?? "0.00").toString().replace(",", ".") );
                        }catch(_){
                            price = 0.00;
                        }
                        if(isNaN(price)){
                            price = 0.00;
                        }
                        const index = Constants.Cart.findIndex(c => c.uuid == params.uuid);
                        if(index > -1){
                            Constants.Cart[index].price = Constants.Cart[index].price + price;
                            Constants.Cart[index].variants.push({
                                uuid: uuidv4(),
                                price: price,
                                id: variantId,
                                description: variantDesc
                            });
                            document.querySelector('#input_variant').value = "";
                            setVariants(variantsCopy);
                            setDisplayModal(false);
                            setInLocalStorage();
                            console.log(variantsCopy)
                        }

                    }}>CONFERMA</button>
                </div>
            </Modal>
            <div className="variants-page">
            
                <input type={"text"} className="form-control" id="input_variant" placeholder="Ricerca variante.." onChange={(e) => {
                    
                    if(!e.target.value){
                        setVariants(variantsCopy);
                        return;
                    }

                    const filtered = variantsCopy.filter(v => v.description.toLowerCase().trim().includes(e.target.value.toLocaleLowerCase().trim()));
                    setVariants(filtered);

                }}/>
                {
                    variants && variants.length > 0 ?
                        variants.map((variant, index) => 
                            <div className="variant-line" key={`variant_${variant.id}`} onClick={() => {

                                // Controlla prodotti abbinati alla variante
                                const indexProduct = products.findIndex(p => p.id.toString() == params.id_product.toString());
                                if(indexProduct > -1){
                                    const joinedVariants = products[indexProduct].variants;
                                    if(joinedVariants && joinedVariants.length > 0){
                                        const indexVariant = joinedVariants.findIndex(v => v.variant && v.variant.id.toString() == variant.id.toString());
                                        if(indexVariant > -1){
                                            const _overridePrice = joinedVariants[indexVariant].override_price;
                                            setOverridePrice(_overridePrice);
                                        }
                                    }
                                }
                                setVariantId(variant.id);
                                setVariantDesc(variant.description);

                                setDisplayModal(true);
                            }}>
                                <div>{variant.description}</div>
                            </div>
                        )
                    :
                        <></>
                }
            </div>
            <div style={{height: "100px"}}/>
        </div>
    );
}