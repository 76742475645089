export function Modal({message, children, display, close}){
    return (
        <div className="mall-modal-parent" style={{display: display ? "block" : "none"}}>
            <div className="mall-modal">
                <div className="title">
                    <div>{message}</div>
                    <div onClick={() => close()}><i class="fas fa-times"></i></div>
                </div>
                <div className="body">
                    {children}
                </div>
            </div>
        </div>
    );
}