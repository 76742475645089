import {io} from 'socket.io-client';
import { Constants } from './Hooks';

const WebSocket = {
    
    hasInit: false,
    delegateEvent: null,

    initWs: () => {

        WebSocket.hasInit = true;
        const socket = 
            io(
                "https://v2.mallbox.it",
                {
                    reconnectionDelayMax: 1000,
                    reconnectionAttempts: 10000000,
                    reconnectionDelay: 1000,
                }
            );
        socket.on('connect', () => {
            console.log(">> Connected with websocket");
        });
        socket.on('refresh_tables', (data) => {
            if(Constants.InTablesView){
                const id_enterprise = data.id_enterprise.toString();
                const id_enterprise_ls = JSON.parse(localStorage.getItem('enterprise')).id.toString();
                if(id_enterprise == id_enterprise_ls){
                    console.log(">> Evt clean tables");
                    WebSocket.delegateEvent();
                }
            }
        });
    }

};

export default WebSocket;