export function Loader(){
    return (
        <div className="loader" style={{backgroundColor: 'black', opacity: 0.5}} >
            <div className="container-spinner">
                <div class="spinner-grow text-primary" role="status" style={{width: "50px", height: "50px", background: "var(--secondary)"}}>
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    );
}